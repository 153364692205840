@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  :root {
    --radius: 0.5rem;
  }
  * {
    background-color: #f9f9ff;
  }
  .sidebar_animation {
    animation: sidebar_animation 0.3s ease-in-out;
  }
  .dropDownMenu {
    animation: animation 0.2s linear;
  }
  .blink {
    animation: blink 1.5s linear infinite;
  }
}
/* Toster Background Color Correction */
div {
  background-color: transparent;
  svg {
    background-color: inherit;
  }
}
@keyframes blink {
  0% {
    background-color: rgb(217, 234, 255);

    transform: scale(1.05);
  }
  25% {
    background-color: rgb(217, 234, 255);

    transform: scale(1.1);
  }
  50% {
    background-color: rgb(217, 234, 255);

    transform: scale(1.12);
  }
  100% {
    transform: scale(1);
  }
}
/* From Uiverse.io by david-mohseni */
.loader {
  position: relative;
  width: 54px;
  height: 54px;
  border-radius: 10px;
}

.loader div {
  width: 8%;
  height: 24%;
  background: rgb(128, 128, 128);
  position: absolute;
  left: 50%;
  top: 30%;
  opacity: 0;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  animation: fade458 1s linear infinite;
}

@keyframes fade458 {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.25;
  }
}

.loader .bar1 {
  transform: rotate(0deg) translate(0, -130%);
  animation-delay: 0s;
}

.loader .bar2 {
  transform: rotate(30deg) translate(0, -130%);
  animation-delay: -1.1s;
}

.loader .bar3 {
  transform: rotate(60deg) translate(0, -130%);
  animation-delay: -1s;
}

.loader .bar4 {
  transform: rotate(90deg) translate(0, -130%);
  animation-delay: -0.9s;
}

.loader .bar5 {
  transform: rotate(120deg) translate(0, -130%);
  animation-delay: -0.8s;
}

.loader .bar6 {
  transform: rotate(150deg) translate(0, -130%);
  animation-delay: -0.7s;
}

.loader .bar7 {
  transform: rotate(180deg) translate(0, -130%);
  animation-delay: -0.6s;
}

.loader .bar8 {
  transform: rotate(210deg) translate(0, -130%);
  animation-delay: -0.5s;
}

.loader .bar9 {
  transform: rotate(240deg) translate(0, -130%);
  animation-delay: -0.4s;
}

.loader .bar10 {
  transform: rotate(270deg) translate(0, -130%);
  animation-delay: -0.3s;
}

.loader .bar11 {
  transform: rotate(300deg) translate(0, -130%);
  animation-delay: -0.2s;
}

.loader .bar12 {
  transform: rotate(330deg) translate(0, -130%);
  animation-delay: -0.1s;
}

@keyframes sidebar_animation {
  0% {
    right: -100%;
  }
  100% {
    right: 0%;
  }
}
@keyframes animation {
  0% {
    top: 200%;
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    top: 100%;
    opacity: 1;
  }
}
